import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    ApplicationContext,
    Mode,
    PointPosition,
    TrainingPoint,
    TrainingStep,
    useAppMode,
    useConfiguration,
    useTrainingStep
} from "../ApplicationContext";
import {Button} from "../button";
import {cn} from "@bem-react/classname";
import {useHistory} from "react-router";
import {useDropzone} from 'react-dropzone';
import CryptoJS from 'crypto-js';

import {ContentState, convertFromHTML, EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import close from './close.svg';
import {Resizable} from "re-resizable";

const {MegadraftEditor} = require('megadraft');

export const TrainingPointDisplay = ({trainingStep, point, index}: { trainingStep: TrainingStep, point: TrainingPoint, index: number }) => {
    const style = cn("training-point");
    const [open, setOpen] = useState(false);
    const {configuration, update} = useConfiguration();
    const mode = useAppMode();
    const onDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        let parentElement = event.currentTarget.parentElement?.parentElement;
        if (!parentElement) return;
        let parent = parentElement.getBoundingClientRect();
        let bubble = event.currentTarget.getBoundingClientRect();
        let x = (event.clientX - bubble.width / 2 - parent.x) / parent.width;
        let y = 1 - (event.clientY + bubble.height / 2 - parent.y) / parent.height;
        if (x < 0 || x > 1 || y > 1 || y < 0) {
            if (window.confirm("Do you want to remove that point?")) {
                trainingStep.points.splice(index, 1);
            }
        } else {
            trainingStep.points[index] = {...point, x, y};
        }
        if (configuration)
            update(configuration);
    }, [configuration, index, point, trainingStep.points, update])
    let onClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setOpen(!open);
    }, [open]);
    return <div className={style({open})} style={{
        bottom: `${point.y * 100}%`,
        left: `${point.x * 100}%`,
    }} onClick={(e) => e.stopPropagation()}>
        <div draggable={mode === Mode.EDITOR}
             onDragEnd={onDrag}
             style={{animation: `fadeIn 1s`}}
             className={style('num')}
             onClick={onClick}>
            <>
                <img className={open ? style('visible') : undefined} src={close} alt={"X"}/><span className={!open ? style('visible') : undefined}>{index + 1}</span>
            </>
        </div>
        <Bubble trainingStep={trainingStep} point={point} index={index} open={open}/>
    </div>
}

export const Bubble = ({trainingStep, point, index, open}: { open:boolean, trainingStep: TrainingStep, point: TrainingPoint, index: number }) => {
    const {configuration, update} = useConfiguration();
    const isEditing = useAppMode() === Mode.EDITOR;
    const [editor, setEditor] = useState(() => {
        const blocksFromHTML = convertFromHTML(point.content);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        return EditorState.createWithContent(state);
    });
    const onContentChange = useCallback((state) => {
        let content = stateToHTML(state.getCurrentContent());
        setEditor(state);
        point.content = content;
        if (configuration)
            update(configuration);
    }, [configuration, point.content, update]);
    const bubbleDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        let parentElement = event.currentTarget.parentElement;
        if (!parentElement) return;
        let parent = parentElement.getBoundingClientRect();
        let position = point.position;
        if (event.clientX > parent.x && event.clientY > parent.y) {
            position = PointPosition.BOTTOM_RIGHT;
        }
        if (event.clientX > parent.x && event.clientY < parent.y) {
            position = PointPosition.TOP_RIGHT;
        }
        if (event.clientX < parent.x && event.clientY > parent.y) {
            position = PointPosition.BOTTOM_LEFT;
        }
        if (event.clientX < parent.x && event.clientY < parent.y) {
            position = PointPosition.TOP_LEFT;
        }
        trainingStep.points[index] = {...point, position};
        if (configuration)
            update(configuration);
    }, [configuration, index, point, trainingStep.points, update]);
    const ref = useRef<HTMLDivElement>(null);
    const style = cn("training-point");
    const [{width, height}, setSize] = useState({width: (point.size || 150), height: (point.size || 150)});

    useEffect(() => {
        if(isEditing) {
            const {size = 150} = point;
            if(size !== width) {
                trainingStep.points[index] = {...point, size: width};
            }
            if(configuration) {
                let timeout = setTimeout(() => update(configuration), 1500);
                return () => {clearTimeout(timeout);}
            }
        }
        return () => {};
    }, [configuration, index, isEditing, point, trainingStep.points, update, width])

    const translate = useMemo(() => {
        //                            0.70710678118 = cos(45°)
        const diff = (width/2) * (1 - 0.70710678118); // = (radius - radius * cos(45°))
        const r = point.position.charAt(1) === "r";
        const t = point.position.charAt(0) === "t";
        let vx = (r ? -1 : 1) * (diff) - (r ? -1 : 1) * 20;
        let vy = (t ? 1 : -1) * (diff) - (t ? 1 : -1) * 20;
        return {transform: `translate(${vx}px, ${vy}px)`};
    }, [point.position, width]);

    if (isEditing) {
        return <Resizable
            size={{width, height}}
            onResizeStop={(e, direction, ref, d) => {
                setSize({
                    width: width + d.width,
                    height: height + d.height,
                });
            }}
            lockAspectRatio={1}
            minHeight={150}
            minWidth={150}
            as={"div"}
            className={style('bubble', {pos: point.position})}
            style={{position: "absolute", ...(translate)}}
        >
            <div
                draggable={!open}
                onDrag={bubbleDrag}
                className="text"
            >
                {open ?
                    <MegadraftEditor
                        editorState={editor}
                        onChange={onContentChange}
                        placeholder='Add some text'/>:
                    <div dangerouslySetInnerHTML={{__html: point.content}}/>
                }
            </div>
        </Resizable>
    } else {
        return <div
            ref={ref}
            className={style('bubble', {pos: point.position})}
            style={{height, width,...(translate)}}
        >
            <div className="text">
                <div dangerouslySetInnerHTML={{__html: point.content}}/>
            </div>
        </div>
    }


}

export const TrainingMain = () => {
    const {hasNext, goNext, training, trainingStep, goToSubStep} = useTrainingStep();
    const {getRootProps, getInputProps} = useDropzone();
    const isEditing = useAppMode() === Mode.EDITOR;
    const context = useContext(ApplicationContext);
    const configuration = useConfiguration();
    const style = cn("training");
    const {push} = useHistory();
    const upload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files?.[0];
        if (!file) return;
        if (file) {
            const reader = new FileReader();
            if (!file.type.startsWith("image")) {
                alert("file is not an image!");
                return;
            }
            reader.addEventListener(
                'load',
                async function () {
                    var wordArray = CryptoJS.lib.WordArray.create(this.result);
                    let md5 = CryptoJS.MD5(wordArray).toString();
                    if (file) {
                        let name = file.name.split('.');
                        let contentName = md5 + "." + name[name.length - 1];
                        await context.upload(contentName, file);
                        if (configuration.configuration && trainingStep) {
                            trainingStep.image = contentName;
                            configuration.update(configuration.configuration);
                        } else {
                            alert("Unable to set the image to an active image step");
                        }
                    }
                }
            );
            reader.readAsArrayBuffer(file);
        }
    }, [configuration, context, trainingStep]);

    const [pointsContainerHeight, setPointsContainerHeight] = useState(undefined);
    const imgRef = useCallback(node => {
        if (node !== null) {
            const rect = node.getBoundingClientRect();
            setPointsContainerHeight(rect.height);
        }
    }, []);

    return <div className={style('main-tab')}>
        <input {...getInputProps()} onChange={upload}/>

        {isEditing && trainingStep &&
        <div className={style('editor-buttons')}>
            <Button className={style('button-next')} onClick={() => {
                if (trainingStep) {
                    trainingStep.points = [
                        ...trainingStep.points,
                        {
                            content: 'write it',
                            x: 0.5,
                            y: 0.5,
                            position: PointPosition.BOTTOM_LEFT
                        }
                    ]
                    if (configuration.configuration) {
                        configuration.update(configuration.configuration);
                    }
                }
            }}>Add a point</Button>
        </div>
        }
        <div style={{animation: `fadeIn 1s`}} className={style('main-content')}>
            <div style={{
                position: "relative",
                width: "100%",
                height: pointsContainerHeight !== undefined && pointsContainerHeight !== 0 ? pointsContainerHeight : "auto",
            }}>
            {
                trainingStep?.image !== undefined ?
                <img ref={imgRef} style={{animation: `fadeIn 1s`}} src={`/data/${trainingStep?.image}`} alt={training?.title}
                     {...(isEditing ?
                    getRootProps({className: style('main-image')}) :
                    {className: style('main-image')})}/> :
                    <div
                        {...(isEditing ?
                            getRootProps({className: style('main-image')}) :
                            {className: style('main-image')})}
                    />
            }
            {
                trainingStep?.points.map((p, i) => <TrainingPointDisplay trainingStep={trainingStep}
                                                                         key={p.x + " " + p.y} index={i} point={p}/>)
            }
            </div>
        </div>
        <div className={style('main-buttons')}>
            <div className={style('button-steps')}>
                {
                    training && training.trainingSteps && (training.trainingSteps.length > 1 || isEditing) ?
                        training.trainingSteps.map(
                            (s, i) =>
                                <Button key={"step-" + i} className={style('button-step', {
                                    notCurrent: s !== trainingStep
                                })} onClick={() => goToSubStep(i)}>
                                    Step {i + 1}
                                    {
                                        isEditing ?
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                if (window.confirm("Do you want to delete this step?")) {
                                                    if (training) {
                                                        training.trainingSteps.splice(i, 1);
                                                        if (configuration.configuration) {
                                                            configuration.update(configuration.configuration);
                                                        }
                                                    }
                                                }
                                            }
                                            }>&nbsp;(X)</span> :
                                            null
                                    }
                                </Button>
                        )
                        : null
                }
                {
                    isEditing ?
                        <Button onClick={() => {
                            if (training) {
                                training.trainingSteps = [
                                    ...(training.trainingSteps ? training.trainingSteps : []),
                                    {
                                        image: trainingStep ? trainingStep.image : "",
                                        points: []
                                    }
                                ]
                                if (configuration.configuration) {
                                    configuration.update(configuration.configuration);
                                }
                            }
                        }}>+</Button> :
                        null
                }
            </div>
            <div>{
                hasNext ?
                    <Button className={style('button-next')} onClick={goNext}>Next</Button>
                    : <Button className={style('button-next')} onClick={() => push('/')}>Go back home</Button>}</div>
        </div>

    </div>
}
