import React from 'react';
import {cn} from '@bem-react/classname';
import {
    useHistory,
} from "react-router-dom";
import "./footer.scss";

function Footer() {
    const style = cn('footer');
    const h = useHistory();
    return (
        <div className={style()}>
            <button className={style('link')} onClick={() => {h.push('/cookie-notice')}}>Cookie Notice</button>
        </div>
    );
}

export default Footer;