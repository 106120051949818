import React from 'react';
import ReactDOM from 'react-dom';
import 'megadraft/dist/css/megadraft.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {Application, ApplicationContext} from "./ApplicationContext";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApplicationContext.Provider value={new Application()}>
                <App/>
            </ApplicationContext.Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
    onSuccess: registration => {
        console.log("Offline mode available");
    },
    onUpdate: () => {
        console.log("Offline mode updated");
    }
});
