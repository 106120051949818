import React from "react";
import './doRotateScreen.scss';
import {cn} from "@bem-react/classname";
import RotateImg from "../rotate.svg"

export const DoRotateScreen = () => {
    const style = cn('doRotateScreen');

    return <div className={style("container")}>
        <div className={style("block")}>
            <img src={RotateImg} alt={""} />
            <div>Please, turn your device</div>
        </div>
    </div>
}
