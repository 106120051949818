import React, {useCallback, useEffect} from "react";
import {cn} from "@bem-react/classname";
import {RouteProps, useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {Configuration, Training, useConfiguration, Mode, useAppMode, useTrainingStep} from "../ApplicationContext";
import {Button} from "../button";
import ContentEditable from 'react-contenteditable'

import './style.scss';
import {TrainingMain} from "./TrainingMain";

export const TrainingLink = ({trainings, training, index}: { trainings: Training[], training: Training, index: number }) => {
    const {configuration, update} = useConfiguration();
    const mode = useAppMode();
    const onChange = useCallback((value: string) => {
        if (mode === Mode.EDITOR && configuration) {
            training.title = value;
            update(configuration);
        }
    }, [training, mode, update, configuration]);
    const appState = useTrainingStep();
    const isCurrent = appState.training === training;
    const style = cn('training-link');
    return <div onClick={() => {
        appState.goTo(index)
    }} className={style({isCurrent})}>
        <div className={style('number')} onClick={(e) => {
            if (mode === Mode.EDITOR && configuration) {
                e.stopPropagation();
            }
        }} onDoubleClick={(e) => {
            if (mode === Mode.EDITOR && configuration) {
                e.stopPropagation();
                if (window.confirm('Do you want to remove that page?')) {
                    trainings.splice(index, 1);
                    update(configuration);
                }
            }
        }}>{index + 1}</div>
        <ContentEditable
            className={style('text')}
            html={training.title} // innerHTML of the editable div
            disabled={mode === Mode.USER}       // use true to disable editing
            onChange={(e) => onChange(e.target.value)} // handle innerHTML change
            tagName='div' // Use a custom HTML tag (uses a div by default)
        />
    </div>
}

export const TrainingPanel = ({...props}: RouteProps) => {
    const style = cn('training');
    const match = useRouteMatch<{ kind: (keyof Configuration) }>("/p/:kind");
    const {kind} = match?.params || {kind: 'dentist'};
    const {configuration, update} = useConfiguration();
    const isEditing = useAppMode() === Mode.EDITOR;
    const h = useHistory();
    const trainingSteps = useTrainingStep();

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            switch (e.key) {
                case "ArrowLeft":
                case "ArrowUp":
                    trainingSteps.goPrevious();
                    break;
                case "ArrowRight":
                case "ArrowDown":
                    trainingSteps.goNext();
                    break;
                default:
                    break;
            }
        };
        window.addEventListener("keydown", listener);
        return () => window.removeEventListener("keydown", listener);
    }, [trainingSteps])

    if (!configuration) {
        return <></>;
    }

    return <div className={style()}>
        <div className={style('column')}>
            <div className={style('steps')}>
                {(configuration[kind] || []).map((t, i) => <TrainingLink trainings={configuration[kind]} training={t}
                                                                         index={i} key={i}/>)}
                {isEditing &&
                <div className={style('link')} onClick={() => {
                    if (configuration) {
                        configuration[kind] = [
                            ...(configuration[kind] || []),
                            {
                                title: 'write me',
                                trainingSteps: []
                            }
                        ]
                        update(configuration);
                    }
                }}>
                    <div className={style('link-number')}>+</div>
                    <div className={style('link-text')}>Add a page</div>
                </div>
                }
            </div>
            <div className={style('others')}>
                <Button onClick={() => {
                    h.push("/browse")
                }}>All subjects</Button>
            </div>
        </div>
        <div className={style('main')}>
            <TrainingMain/>
        </div>

    </div>
}
