import React, {useContext, useEffect, useState, useLayoutEffect, useCallback, useMemo} from 'react';
import {
    Route,
    useRouteMatch, useHistory
} from "react-router-dom";
import resmedLogo from './resmed-logo.svg';
import narvalLogo from './narval-logo.png';
import narvalLogoMobile from './narval-logo-mobile.svg';
import {cn} from '@bem-react/classname'
import './App.scss';
import {Browse, Home} from "./home";
import {CookieNotice} from "./cookieNotice";
import {ApplicationContext, Mode, useConfiguration, useTrainingStep} from "./ApplicationContext";
import Konami from "react-konami-code";
import {TrainingPanel} from "./training";
import {Button} from './button';
import {DoRotateScreen} from "./doRotateScreen";
import {MenuButton} from "./menuButton";
import Footer from "./footer";

const {AnimatedSwitch} = require('react-router-transition');

declare global {
    interface Window {
        dataLayer:any;
        Optanon:any;
    }
}

function App() {
    const context = useContext(ApplicationContext);
    const configuration = useConfiguration();
    const [mode, setMode] = context.useMode();
    const [godMod, setGodMod] = useState(false);
    const match = useRouteMatch<{ kind: string }>("/p/:kind");
    const h = useHistory();
    const style = cn('app');
    const [screenOrientation, setScreenOrientation] = useState("landscape")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const isMobile = (screenWidth <= 750);

    useEffect(() => {
        let hasGodMod = sessionStorage.getItem("godmod");
        if (hasGodMod) {
            setGodMod(true);
            setMode(Mode.EDITOR);
        }},[setMode])
    useEffect(() => {
        if (godMod) {
            sessionStorage.setItem("godmod", "1");
        } else {
            sessionStorage.removeItem("godmod")
        }

    }, [godMod]);

    const onWindowSizeChange = () => {
        //Fix to get rid of toolbar height on mobile browsers
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        setScreenWidth(window.innerWidth);

        if (window.matchMedia("(orientation: portrait)").matches) {
            setScreenOrientation("portrait")
        }

        if (window.matchMedia("(orientation: landscape)").matches) {
            setScreenOrientation("landscape")
        }
    }
    useEffect(() => {
            onWindowSizeChange();
            window.addEventListener('resize', onWindowSizeChange);
            return () => {
                window.removeEventListener('resize', onWindowSizeChange);
            }
        },
        [])

    const trainingStep = useTrainingStep();
    const trainingStepKind = trainingStep?.kind;
    const trainingStepTitle = trainingStep?.training?.title;
    const trainingStepStep = trainingStep?.step;
    const path = document.location.pathname;

    const titleGenerate = useCallback((path) => {
        switch(path) {
            case "/":
                return "Narval Easy Training - Home";
            case "/browse":
                return "Narval Easy Training - Browse";
            default:
                if(!trainingStepKind || !trainingStepTitle) return;
                return "Narval Easy Training - " + (trainingStepKind === "dentist" ? "Dentist" : trainingStepKind === "sleep-doctor" ? "Sleep doctor" : "") + " - " + (trainingStepTitle ? trainingStepTitle : "");
        }
    }, [trainingStepKind, trainingStepTitle]);
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        const newTitle = titleGenerate(path);
        if(title !== newTitle && newTitle) {
            setTitle(newTitle);
        }
    }, [path, titleGenerate, title]);
     
    useLayoutEffect(() => {
        if(title) {
            window.document.title = title;
            window.dataLayer.push?.({
                'event': 'virtualPageView',
                'virtualPageURL': document.location.pathname,
                'virtualPageTitle' : title,
            }); 
        }
    }, [title]);

    const headerTitle = useMemo(() => <p className={style("headerTitle")}>
            {trainingStepKind === "dentist" ?
                <span className={style("dentist")}>Dentist features - {trainingStepStep+1}. </span>
            :
                <span className={style("sleep-doctor")}>Sleep doctor features - {trainingStepStep+1}. </span>
            }
            <span>{trainingStepTitle}</span>
        </p>,
        [trainingStepKind, trainingStepTitle, style, trainingStepStep])

    return (
        <div className={style({editing: mode === Mode.EDITOR})}>
            <Konami action={() => {
                setGodMod(!godMod);
            }}>
            </Konami>
            {godMod ?
                <Button className={style('edit-mode')}
                        onClick={() => {
                            setMode(mode === Mode.USER ? Mode.EDITOR : Mode.USER)
                        }}>{
                    mode === Mode.USER ? 'USER' : 'EDIT'
                } MODE</Button> : null}
            {godMod && mode === Mode.EDITOR ?
                <Button onClick={() => configuration.save()} className={style('save-button')}>SAVE</Button> : null}
            <header className={style('header')}>
                {isMobile && path !== "/browse" && path !== "/" ?
                    headerTitle
                :
                    <img src={resmedLogo} alt="resmed" onClick={() => {h.push('/')}}/>
                }
                {isMobile ?
                    <div className={style('mobile-right-side')}>
                        <img src={narvalLogoMobile} alt="narval"/>
                        <MenuButton isOpen={path === "/browse"} />
                    </div>
                    :
                    <img src={narvalLogo} alt="narval"/>
                }
            </header>
            {
                !isMobile || screenOrientation !== "portrait" ?
                    <div className={style('main', {kind: (match && match.params.kind) || undefined})}>
                        <AnimatedSwitch
                            atEnter={{ opacity: 0 }}
                            atLeave={{ opacity: 0 }}
                            atActive={{ opacity: 1 }}
                            className="route-wrapper"
                        >
                            <Route exact path="/" component={Home}/>
                            <Route slug="dentist" path="/p/dentist" component={TrainingPanel}/>
                            <Route slug="sleep-doctor" path="/p/sleep-doctor" component={TrainingPanel}/>
                            <Route exact slug="browse" path="/browse" component={Browse}/>
                            <Route exact slug="cookie-notice" path="/cookie-notice" component={CookieNotice}/>
                        </AnimatedSwitch>
                        <Footer />
                    </div>
                    :
                    ""
            }
            {isMobile && screenOrientation === "portrait" && <DoRotateScreen/>}
        </div>
    );
}

export default App;
