import React from "react"
import {Button} from "../button"
import {useHistory} from "react-router";
import {cn} from "@bem-react/classname";

import iconSleepDoctor from './sleep-doctor.svg';
import iconDentist from './dentist.svg';
import './home.scss';
import {useConfiguration} from "../ApplicationContext";

export const Home = () => {
    const {push} = useHistory();
    const style = cn('home');

    return <>
        <div className={style('choice')}>
            <Button onClick={() => {
                push('/p/dentist')
            }}>
                <img src={iconDentist} alt="dentist"/>
                <h2>I'm a dentist</h2>
                <div>Discover dentist features</div>
            </Button>
            <Button onClick={() => {
                push('/p/sleep-doctor')
            }}>
                <img src={iconSleepDoctor} alt="sleep doctor"/>
                <h2>I'm a sleep doctor</h2>
                <div>Discover prescriber features</div>
            </Button>
        </div>
        <div className={style('end')}>
            <Button onClick={() => {
                push('/browse')
            }}>Browse by topic</Button>
        </div>
    </>
}


export const Browse = () => {
    const style = cn('browse');
    const {configuration} = useConfiguration();
    const h = useHistory();

    return <>
        <div className={style()}>
            <div>
                <div className={style('dentist')}>
                    <h2>Dentist features</h2>
                    <div className={style('features')} style={{gridTemplateRows: Array(Math.floor((configuration?.dentist?.length || 1)/2) + 2).join("1fr ")}}>
                        {
                            configuration?.dentist?.map((t, i) =>
                                <div className={style('feature')} onClick={() => h.push('/p/dentist/' + i)} key={i}>
                                    <div className={style('number')}>{i + 1}</div>
                                    <div className={style('title')} dangerouslySetInnerHTML={{__html: t.title}}/>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={style('sleep-doctor')}>
                    <h2>Sleep doctor features</h2>

                    <div className={style('features')}>
                        {
                            configuration?.['sleep-doctor']?.map((t, i) =>
                                <div className={style('feature')} onClick={() => h.push('/p/sleep-doctor/' + i)} key={i}>
                                    <div className={style('number')}>{i + 1}</div>
                                    <div className={style('title')} dangerouslySetInnerHTML={{__html: t.title}}/>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    </>
}
