import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";

export enum Mode {
    USER, EDITOR
}

type callback = (() => void)[];

export enum PointPosition {
    TOP_RIGHT = "tr", TOP_LEFT = "tl", BOTTOM_LEFT = "bl", BOTTOM_RIGHT = "br"
}

export interface TrainingPoint {
    x: number;
    y: number;
    size?: number;
    content: string;
    position: PointPosition;
}

export interface TrainingStep {
    image: string;
    points: TrainingPoint[];
}

export interface Training {
    title: string;
    trainingSteps: TrainingStep[];
}

export interface Configuration {
    'sleep-doctor': Training[];
    dentist: Training[];
}

export class Application {

    mode: Mode = Mode.USER;
    private onModeChange: callback = [];
    configuration?: Configuration = undefined;
    private onConfigurationChange: callback = [];
    private username: string = "admin";
    private password: string = "supersecret";

    constructor() {
        this.load();
    }

    async load() {
        const data = await fetch('/data/data.json', {
            headers: this.getHeaders()
        });
        if (!data.ok) {
            throw new Error("unable to load configuration");
        }
        this.configuration = await data.json();
        this.onConfigurationChange.forEach((cb) => cb());
    }

    async save() {
        const st = JSON.stringify(this.configuration);
        const blob = new Blob([st], {type: 'application/json'});
        const file = new File([blob], 'data.json');
        const formData = new FormData();
        formData.append('file', file, 'data.json');
        const data = await fetch('/data/data.json', {
            method: 'post',
            body: formData,
            headers: this.getHeaders()
        });
        if (!data.ok) {
            throw new Error("unable to load configuration");
        }
        this.onConfigurationChange.forEach((cb) => cb());
    }

    private getHeaders() {
        return {Authorization: `Basic ${btoa(this.username + ":" + this.password)}`};
    }

    async upload(name: string, file: File) {
        const formData = new FormData();
        formData.append('file', file, name);
        const data = await fetch(`/data/${name}`, {
            method: 'post',
            body: formData,
            headers: {Authorization: `Basic ${btoa(this.username + ":" + this.password)}`}
        });
        if (!data.ok) {
            throw new Error("unable to load configuration");
        }
        return await data.json();
    }

    useConfiguration() {
        return this.useValue<Configuration>('configuration', "onConfigurationChange");
    }

    useMode() {
        return this.useValue('mode', "onModeChange");
    }

    private useValue<T>(key: keyof Application, storage: ("onModeChange" | "onConfigurationChange")): [T, (newValue: T) => void] {
        const [mode, setMode] = useState<T>(() => (this[key] as any as T));
        useEffect(() => {
            let callback = () => {
                setMode(this[key] as any as T)
            };
            this[storage] = [callback, ...this[storage]];
            if(mode !== (this[key] as any)) {
                setMode(this[key] as any as T);
            }
            return () => {
                this[storage].slice(this[storage].indexOf(callback), 1)
            }
        }, [key, mode, storage]);
        let setter = useCallback((value: T) => {
            this[key] = (value as any);
            this[storage].forEach((cb) => cb());
        }, [key, storage]);
        return [mode, setter];
    }
}

export type useConfigurationReturn = { configuration?: Configuration, update: (configuration: Configuration) => void, save: () => Promise<void> };
export const useConfiguration = (): useConfigurationReturn => {
    let application = useContext(ApplicationContext);
    let [configuration, setConfiguration] = application.useConfiguration();
    let update = useCallback((configuration: Configuration) => {
        setConfiguration(JSON.parse(JSON.stringify(configuration)));
    }, [setConfiguration]);
    return {
        configuration,
        update,
        save: () => application.save()
    }
}

export const useAppMode = () => {
    let application = useContext(ApplicationContext);
    let [mode] = application.useMode();
    return mode;
}

export type TrainingState = {
    kind?: keyof Configuration;
    step: number;
    subStep: number;
    training?: Training;
    trainingStep?: TrainingStep;
    hasNext: boolean;
    goNext: () => void,
    goPrevious: () => void,
    goTo: (step: number) => void,
    goToSubStep: (subStep: number) => void
}

export const useTrainingStep = (): TrainingState => {
    const {configuration} = useConfiguration();
    const match = useRouteMatch<{ kind: (keyof Configuration), step?: string, substep?: string }>({
        path: [
            "/p/:kind/:step/:substep",
            "/p/:kind/:step",
            "/p/:kind"
        ]
    });
    const h = useHistory();
    const step = match && match.params.step ? parseInt(match.params.step) : 0;
    const subStep = match && match.params.substep ? parseInt(match.params.substep) : 0;
    const kind = match?.params.kind;
    const steps: ([number, number, Training, TrainingStep])[] = [];
    if(kind) {
        configuration?.[kind]?.forEach((step, stepNumber) => {
            step.trainingSteps.forEach((subStep, subStepNumber) => {
                steps.push([stepNumber, subStepNumber, step, subStep]);
            })
        })
    }
    const currentStepIndex = steps.findIndex((e) => e[0] === step && e[1] === subStep);
    const training = currentStepIndex >= 0 ? steps[currentStepIndex][2] : undefined;
    const trainingStep = currentStepIndex >= 0 ? steps[currentStepIndex][3] : undefined;
    const next = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : undefined;
    const previous = currentStepIndex > 0 ? steps[currentStepIndex - 1] : undefined;
    return {
        kind,
        step,
        subStep,
        training,
        trainingStep,
        hasNext: next !== undefined,
        goNext: () => {
            if (next) {
                h.push(`/p/${kind}/${next[0]}/${next[1]}`)
            }
        },
        goPrevious: () => {
            if (previous) {
                h.push(`/p/${kind}/${previous[0]}/${previous[1]}`)
            }
        },
        goTo: (wantedStep) => {
            h.push(`/p/${kind}/${wantedStep}`)

        },
        goToSubStep: (wantedStep) => {
            h.push(`/p/${kind}/${step}/${wantedStep}`)
        }
    }
}
 
export const ApplicationContext = createContext<Application>(undefined as any);
