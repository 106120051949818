import React from "react";
import MenuOpen from "../menu-open.svg"
import MenuClose from "../menu-close.svg"
import {useHistory} from "react-router-dom";

export const MenuButton = ({isOpen = false}) => {
    const h = useHistory();

    if (isOpen)
        return <img src={MenuClose} onClick={() => h.goBack()} alt={"Close menu"}/>
    else
        return <img src={MenuOpen} onClick={() => h.push("/browse")} alt={"Open menu"}/>
}
