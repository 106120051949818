import React from "react"
import {cn} from "@bem-react/classname";

import './cookieNotice.scss';

export const CookieNotice = () => {
    const style = cn('cookie-notice');

    return <>
        <div className={style('content')}>
            <h1>Cookie policy</h1>
            <h3>Tracers and other technologies</h3>
            <p style={{textAlign: "right"}}>Date of last revision: 16 February 2022</p><br/><br/><br/>
            <p>Browsing on this site leads to the storage of cookies. ResMed uses cookies and other technologies to enhance user experience on its websites and mobile apps.</p>
            <p>This notice explains how we use these technologies, the choices that we make and your rights.</p>
            <h2>1. What are cookies and other similar technologies?</h2>
            <p>Cookies are small text files created by the websites you visit and saved on your terminal, gathering a certain amount of data about your browsing history (e.g. date, time and number of visits, source of arrival and preferences). These cookies store information on your device. Other tracking technologies are used for similar purposes.</p>
            <p><b>Persistent cookies</b> allow us to recognise you as a user and permit you to return to our password-protected websites without having to re-enter your login details. Once you are logged in, a persistent cookie stays in your browser and is read by ResMed when you return to one of our websites.</p>
            <p>Persistent cookies are stored on your terminal until they expire (maximum 13 months) or until you delete it them.</p>
            <p><b>Session cookies</b> are deleted automatically when you quit your browser.</p>
            <h2>2. Which types of cookies do we use?</h2>
            <p>2.1. Strictly Necessary Cookies</p>
            <p>These cookies ensure the essential functionalities of the site and its correct operation. They cannot be deactivated from our systems. However, you can configure your browser to block them or be notified of their use. Some parts of the site may not function, where applicable.</p>
            <p>The use of these cookies by our site is based on our legitimate interest in order to ensure the security, technical operation and accessibility of the site and our different services.</p>
            <p>2.2. Performance cookies</p>
            <p>We use performance cookies to enable us to analyse page visits (number of visits, visitor activities and frequency of return), how you interact with our site, to find out more about your path between pages, to improve our website and to adapt it to your preferences. These cookies are used solely to improve the website’s performance and adapt the experience to your needs.</p>
            <p>The use of these cookies is based on your consent; if you opt out of these cookies, we will be unable to determine when you visited our site or your “clickstream” data on our site.</p>
            <p>2.3. Functionality cookies</p>
            <p>We use “functional” cookies to provide you with multimedia content and keep your information securely while you are logged in.</p>
            <p>Functional cookies allow us to save the information that has already been entered (such as the names of users and language choices) so as to offer you improved functionalities. These cookies are not used to identify you personally and do not track your movements on other websites.</p>
            <p>The use of these cookies is based on your consent; if you opt out of these cookies, we will be unable to determine when you visited our site or your “clickstream” path on our site.</p>
            <p>2.4. Cookies for targeted advertising</p>
            <p>These cookies allow us to propose personalised advertisements to you according to your browsing history on the site or the management of our advertising campaigns.
            <br/>
            These cookies can be activated on our site by our advertising partners and, therefore, are used to establish a profile on your interests and propose targeted advertising to you on other internet sites.</p>
            <p>The use of these cookies is based on your consent; if you opt out of these cookies, advertising will be proposed to you that is not targeted to your interests.</p>
            <p>2.5. “Social network” cookies</p>
            <p>Third-party social network modules or buttons appear on some pages of the site (Facebook, Twitter, LinkedIn and YouTube, etc.). They allow you to use these networks’ functionalities such as sharing, viewing or liking content directly via our site.</p>
            <p>If you use the functionalities connected to social networks, information about you will be collected. This allows ResMed to identify the number of video views or number of post/video shares.</p>
            <p>The use of these cookies is based on your consent; if you opt out of these cookies, the functionalities linked to social networks will not work and we will not collect information about your use of these social networks.</p>
            <p>2.6. Web beacons or GIFs</p>
            <p>On some of our websites, we may also use invisible pixels (also called transparent GIFs, invisible GIFs, pixels or action tags) which allow us to offer you a better service by adjusting the content of our website, to better target the interactive advertising and to make the site more user-friendly. These technologies are code fragments presented in the form of a tiny graphic image integrated in a webpage or email. Invisible pixels can recognise certain types of information on your computer, such as cookies, the time and date you view a page as well as a description of the page on which the invisible pixel is stored.</p>
            <p>We use invisible pixels for different tracking purposes, for example:</p>
            <ul>
                <li>to determine whether you are connected to the site or a third-party service;</li>
                <li>to measure behaviour: page visit, etc.</li>
                <li>to collect technical information about the individual initiating the behaviour: IP addresses, machine configuration, etc.</li>
            </ul>
            <h2>3. How do I manage cookies?</h2>
            <p>3.1. Accept, opt out of or configure cookies on your first visit</p>
            <p>Storing cookies on your terminal involves obtaining your consent or our legitimate interest when the cookies are strictly necessary. Therefore, as soon as you come on to our site, an information banner tells you that we use this technology and by continuing to browse you agree to cookies being stored on your terminal.</p>
            <p>Deleting functionality cookies that are necessary for ResMed to provide content may prevent you from accessing certain site content or result in degraded operation.</p>
            <p>3.2. Configure cookies at any time</p>
            <p>You can change your cookie preferences at any time by clicking on <button className={style('link')} onClick={() => window.Optanon.ToggleInfoDisplay()}>Cookie Settings</button>.</p>
            <p>You can also manage cookie storage using your browser’s settings as described below.</p>
            <p>You can configure your browsing software so that cookies are saved on your terminal or, on the contrary, so that they are rejected, either systematically or according to their issuer. You can also configure your browsing software so that cookie acceptance or rejection is proposed to you before a cookie is able to be saved to your terminal.</p>
            <p>This configuration may result in changes to your Internet browsing and your conditions of access to certain services requiring the use of cookies.</p>
            <p>This configuration will not prevent advertising being displayed on the websites you visit but will block the technologies allowing advertising to be adapted to your focuses of interest.</p>
            <p>3.3. Accept, opt out of or configure cookies via your mobile terminal</p>
            <p>You can decide to reinitialise your advertising identifier and to change the confidentiality settings of your mobile telephone.</p>
            <p>To configure your confidentiality settings:</p>
            <ul>
                <li>Apple System: <button className={style('link')} onClick={() => window.open('https://support.apple.com/fr-fr/HT201265', '_blank')}>https://support.apple.com/fr-fr/HT201265</button></li>
                <li>Android System: <button className={style('link')} onClick={() => window.open('https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=fr', '_blank')}>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=fr</button></li>
                <li>Microsoft System: <button className={style('link')} onClick={() => window.open('https://support.microsoft.com/fr-fr/topic/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d', '_blank')}>https://support.microsoft.com/fr-fr/topic/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d</button></li>
            </ul>
        </div>
    </>
}
